<template>
  <v-form>
    <v-text-field
      v-model="profile.nickname"
      label="Nickname"
      :error-messages="error['nickname']"
    ></v-text-field>

    <v-text-field
      v-model="profile.postcode"
      label="Postcode"
      maxlength="4"
      @input="profile.postcode = profile.postcode.toUpperCase()"
      persistent-hint
      hint="Enter the first part of your postcode eg NW1."
      :error-messages="error['postcode']"
    ></v-text-field>

    <v-select
      label="Gender"
      :items="genders"
      item-text="text"
      item-value="value"
      v-model="profile.gender"
    ></v-select>

    <v-text-field
      v-model="profile.dob"
      label="Date of birth"
      type="date"
      persistent-hint
      hint="Your actual date of birth won't be displayed, we just use this to
		calculate your age group."
    ></v-text-field>

    <br />

    <v-text-field
      v-model="profile.paddling_since"
      label="Paddling since"
      type="date"
      persistent-hint
      hint="When did you start paddling regularly?"
    ></v-text-field>

    <v-text-field
      persistent-hint
      v-model="profile.british_canoeing_membership_number"
      label="British Canoeing Membership Number"
      :error-messages="error['british_canoeing_membership_number']"
      hint="Your last name on your AquaPaddle account and your British
	  Canoeing account must match in order for this to work."
    ></v-text-field>
    <p></p>

    <v-alert
      outlined
      text
      color="primary"
      v-if="profile.british_canoeing_membership_number == null"
    >
      <h3>British Canoeing Members</h3>
      <div class="mb-3">
        Enter your British Canoeing membership number above to display the logo
        on your public profile. Not a British Canoeing member yet?
        <a
          href="https://britishcanoeing.justgo.com/Account.mvc/Login"
          target="_blank"
          >Click here to join British Canoeing</a
        >.
      </div>
    </v-alert>
    <v-btn color="primary" @click="submit">Save</v-btn>
  </v-form>
</template>

<script>
import store from "@/store/index";

export default {
  name: "edit-form",

  data: () => ({
    error: "",
    profile: {},
    genders: [
      { text: "Male", value: "m" },
      { text: "Female", value: "f" },
      { text: "Other", value: "o" },
      { text: "Prefer not to say", value: "x" },
    ],
  }),

  methods: {
    submit() {
      const form = new FormData();
      form.append("[profile]nickname", this.profile.nickname);
      form.append("[profile]gender", this.profile.gender);
      form.append("[profile]dob", this.profile.dob);
      form.append("[profile]postcode", this.profile.postcode);
      form.append("[profile]paddling_since", this.profile.paddling_since);

      if (this.profile.british_canoeing_membership_number) {
        form.append(
          "[profile]british_canoeing_membership_number",
          this.profile.british_canoeing_membership_number
        );
      }

      if (this.profile.british_canoeing_membership_number) {
        form.append("[profile]postcode", this.profile.postcode);
      }

      fetch(`${process.env.VUE_APP_BACKEND_API_URL}/user/profile`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        method: "PATCH",
        body: form,
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          store.commit("flash", { type: "success", text: "Saved" });
        }
      });
    },

    getProfile() {
      fetch(`${process.env.VUE_APP_BACKEND_API_URL}/user/profile`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.profile = data;
        }
      });
    },
  },

  mounted() {
    this.getProfile();
  },
};
</script>
