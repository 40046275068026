<template>
  <v-container>
    <page-header text="My Profile" />

    <v-row>
      <v-col class="text-center">
        <v-chip label small class="mb-1"> AquaPaddles completed </v-chip>
        <br />
        <v-chip label small>
          Member since
          {{ this.prettyDate(profile.created_at) }}
        </v-chip>
      </v-col>
    </v-row>

    <v-row v-if="profile.tag">
      <v-col class="text-center">
        <number-plate :tag="profile.tag" />
      </v-col>
    </v-row>

    <p
      v-if="profile.british_canoeing_membership_number !== null"
      class="text-center"
    >
      <img src="/img/british-canoeing-logo.png" height="60" />
    </p>

    <v-row class="mb-5">
      <v-col class="text-center">
        <DownloadBibButton />
      </v-col>
    </v-row>

    <edit-form />
  </v-container>
</template>

<script>
import PageHeader from "@/components/public/PageHeader.vue";
import EditForm from "@/components/user/profile/EditForm.vue";
import DownloadBibButton from "@/components/public/profile/DownloadBibButton.vue";
import NumberPlate from "@/components/user/profile/NumberPlate.vue";

export default {
  name: "profile-edit",

  components: {
    PageHeader,
    EditForm,
    DownloadBibButton,
    NumberPlate,
  },

  data: () => ({
    error: "",
    profile: {},
  }),

  methods: {
    getProfile() {
      fetch(`${process.env.VUE_APP_BACKEND_API_URL}/user/profile`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.profile = data;
        }
      });
    },
  },

  mounted() {
    this.getProfile();
  },
};
</script>
