<template>
  <div class="d-flex justify-center mt-8">
    <div
      v-if="!race_registrations.find((item) => item.race.id == race_id)"
      class="text-center"
    >
      <a
        href="https://aquapaddle.org/aquapaddle-event-terms-conditions"
        target="_blank"
      >
        Click here to read event terms, conditions and disclaimer
      </a>

      <v-checkbox
        v-model="termsAccepted"
        label="I accept the terms and conditions and agree the disclaimer"
      >
      </v-checkbox>

      <v-checkbox
        v-model="canSwim"
        label="I am water confident and able to swim 20m in my paddling clothing"
      >
      </v-checkbox>

      <v-checkbox
        v-model="hasMedical"
        label="I have medical conditions the organisers should know about"
        @change="populateMedicalDetails"
      >
      </v-checkbox>
      <p v-if="hasMedical">
        <a href="/account" target="_blank"
          >Please click here to update any new information into your Medical
          Notes on the Account Page</a
        >
      </p>

      <p>
        <v-textarea
          v-model="medical"
          v-if="hasMedical"
          label="Enter your medical conditions here"
        ></v-textarea>
      </p>

      <h4 class="mt-6 mb-5">What watercraft will you be using?</h4>

      <v-select
        solo
        :items="watercraft"
        v-model="chosenWatercraft"
        placeholder="Select your watercraft"
      ></v-select>

      <v-btn color="accent" class="mb-6" block large rounded @click="enter_race"
        >Enter this event
      </v-btn>
    </div>

    <div
      class=""
      v-if="race_registrations.find((item) => item.race.id == race_id)"
    >
      <v-alert type="success" outlined text>
        <h3>You are in this event</h3>
        Please make sure you bring your waterproof forever AquaPaddle Number to
        the Event that is worn so it is visible from the front. Arrive with
        enough time to prepare your paddle board, kayak or canoe. The Safety
        Briefing will be about 20 minutes before the start of the AquaPaddle at
        the Meeting Point.
      </v-alert>

      <v-alert outlined text color="accent" icon="mdi-heart">
        <h3>Make a difference</h3>
        <div class="mb-3">
          If you feel that AquaPaddle is a benefit to you, please consider
          making us a donation, for example £10? It all goes to making
          AquaPaddle the best it can be!
        </div>
        <v-btn
          href="https://donate.kindlink.com/AquaPaddle/4769/0"
          target="_blank"
          color="accent"
          large
          rounded
          dark
          >Donate Now</v-btn
        >
      </v-alert>
    </div>
  </div>
</template>

<script>
import store from "@/store/index";

export default {
  name: "enter-race-button",

  props: ["race_id"],

  data: () => ({
    race_registrations: [],
    termsAccepted: false,
    canSwim: false,
    hasMedical: false,
    medical: "",
    chosenWatercraft: "",
    watercraft: [
      "All rounder SUP",
      "Touring SUP",
      "2 Person SUP",
      "Racing Inflatable SUP",
      "Racing Hardboard SUP",
      "Prone",
      "K1",
      "Sit On Top Kayak",
      "2 Person Kayak",
      "Inflatable Kayak",
      "2 Person Inflatable Kayak",
      "Sea-Touring Kayak",
      "Canoe",
      "2 Person Canoe",
      "Other",
    ],
  }),

  methods: {
    enter_race() {
      if (!this.termsAccepted) {
        var message = "Please accept the terms & conditions";
        store.commit("flash", { type: "error", text: message });
        return;
      }

      if (!this.canSwim) {
        var swimMessage = "Please confirm you can swim";
        store.commit("flash", { type: "error", text: swimMessage });
        return;
      }

      if (this.hasMedical && !this.medical) {
        var medicalMessage = "Please enter your medical conditions";
        store.commit("flash", { type: "error", text: medicalMessage });
        return;
      }

      if (!this.chosenWatercraft) {
        var watercraftMessage = "Please choose a watercraft";
        store.commit("flash", { type: "error", text: watercraftMessage });
        return;
      }

      var url = "";
      url += process.env.VUE_APP_BACKEND_API_URL;
      url += "/user/race-registrations";

      const form = new FormData();
      form.append("race_id", this.race_id);
      form.append("watercraft", this.chosenWatercraft);
      form.append("medical", this.medical);

      fetch(url, {
        method: "POST",
        body: form,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.getData();
        }
      });
    },

    getData() {
      var url = "";
      url += process.env.VUE_APP_BACKEND_API_URL;
      url += "/user/race-registrations";

      fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.race_registrations = data;
        }
      });
    },

    populateMedicalDetails() {
      if (this.hasMedical) {
        var url = "";
        url += process.env.VUE_APP_BACKEND_API_URL;
        url += "/user/account";

        fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }).then(async (response) => {
          const data = await response.json();

          if (!response.ok) {
            this.error = data;
          }

          if (response.ok) {
            this.medical = data["medical"];
          }
        });
      } else this.medical = "";
    },
  },

  mounted() {
    this.getData();
  },
};
</script>
