<template>
  <v-sheet
    elevation="3"
    class="px-4 py-2"
    :class="{ medical: paddler.medical }"
  >
    <v-icon v-if="icon" class="mr-4" :color="color">mdi-{{ icon }}</v-icon>

    <div class="position mr-4 primary--text" v-if="position">
      {{ position }}
    </div>
    <div>
      <div class="tag">
        {{ paddler.tag }}
      </div>
      <div>
        <span class="name">
          {{ paddler.first_name }}
        </span>
        <span class="time" v-if="time">
          {{ hms(paddler.time) }}
        </span>
      </div>
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: "PaddleTimerButton",

  props: ["paddler", "icon", "color", "position", "time"],

  data() {
    return {};
  },

  methods: {
    hms(time) {
      var hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((time % (1000 * 60)) / 1000);
      var result = "";
      result += String(hours).padStart(2, "0");
      result += ":";
      result += String(minutes).padStart(2, "0");
      result += ":";
      result += String(seconds).padStart(2, "0");
      return result;
    },

    prettyTime(time) {
      return new Date(time * 1000).toISOString().substr(11, 8);
    },
  },
};
</script>

<style scoped>
.v-sheet {
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
}

.v-sheet.medical {
  border: 1px solid red;
  color: red;
}

@media screen and (max-width: 600px) {
  .col-6 .v-sheet,
  .col-4 .v-sheet {
    flex-direction: column;
    text-align: center;
  }

  .col-6 i,
  .col-4 i {
    margin-right: 0 !important;
    margin-bottom: 10px;
  }
}

.tag {
  font-weight: bold;
  font-family: monospace;
}

.v-icon.v-icon {
  font-size: 40px;
}

.position {
  font-size: 40px;
}
</style>
