<template>
  <h4 class="text-h3 accent--text font-weight-bold">
    {{ tag.substr(0, 3) }}
    <br />
    {{ tag.substr(3, 5) }}
  </h4>
</template>

<script>
export default {
  name: "number-plate",

  props: ["tag"],
};
</script>
