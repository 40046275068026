<template>
  <v-container style="max-width: 500px">
    <page-header text="Paddle Organiser" />
    <Menu />
    <h2 class="text-h5 mb-6 text-center">Create New Event</h2>
    <Form :race="race" :error="error" />
    <div class="d-flex">
      <v-btn color="primary" @click="submit">Save</v-btn>
      <v-btn class="ml-auto" to="/organiser">Cancel</v-btn>
    </div>
  </v-container>
</template>

<script>
import store from "@/store/index";
import PageHeader from "@/components/public/PageHeader.vue";
import Form from "@/components/organiser/Form.vue";
import Menu from "@/components/organiser/Menu.vue";

export default {
  name: "Create",
  components: {
    PageHeader,
    Form,
    Menu,
  },
  data() {
    return {
      error: "",
      race: {
        name: "",
        description: "",
        meeting_point: "",
        location_id: "",
        max_registrations: "",
        registering: true,
        arrival_time: "",
        scheduled_start_time: "",
      },
    };
  },

  methods: {
    submit() {
      const form = new FormData();
      form.append("[race]name", this.race.name);
      form.append("[race]description", this.race.description);
      form.append("[race]meeting_point", this.race.meeting_point);
      form.append("[race]max_registrations", this.race.max_registrations);
      form.append("[race]registering", this.race.registering);
      form.append("[race]arrival_time", this.race.arrival_time);
      form.append("[race]scheduled_start_time", this.race.scheduled_start_time);
      form.append("[race]location_id", this.race.location_id);

      fetch(`${process.env.VUE_APP_BACKEND_API_URL}/organiser/races`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        method: "POST",
        body: form,
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
          store.commit("flash", {
            type: "error",
            text: "Something went wrong",
          });
        }

        if (response.ok) {
          this.$router.push({ name: "Organiser" });
          store.commit("flash", { type: "success", text: "Saved" });
        }
      });
    },
  },
};
</script>
