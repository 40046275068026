<template>
  <v-tooltip left v-if="watercraft">
    <template v-slot:activator="{ on, attrs }">
      <img
        :src="'/watercraft/' + watercraft + '.svg'"
        height="36"
        width="36"
        style="margin: 3px"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <span>{{ watercraft }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "watercraft-icon",

  props: ["watercraft"],
};
</script>
