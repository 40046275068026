import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";
import SignIn from "../views/SignIn.vue";
import SignUp from "../views/SignUp.vue";
import Account from "../views/Account.vue";
import Profile from "../views/public/Profile.vue";
import UserProfileEdit from "../views/user/ProfileEdit.vue";
import Race from "@/views/public/Race.vue";
import Races from "@/views/public/Races.vue";
import FinishedRaces from "@/views/public/FinishedRaces.vue";
import Location from "@/views/public/Location.vue";
import About from "@/views/public/About.vue";
import Terms from "@/views/public/Terms.vue";
import EventTerms from "@/views/public/EventTerms.vue";
import Privacy from "@/views/public/Privacy.vue";
import UserRaceRegistrations from "@/views/user/UserRaceRegistrations.vue";
import Contact from "@/views/public/Contact.vue";
import Timer from "@/views/Timer.vue";
import OrganiserEdit from "@/views/organiser/Edit.vue";
import OrganiserUpcoming from "@/views/organiser/Upcoming.vue";
import OrganiserFinished from "@/views/organiser/Finished.vue";
import OrganiserCreate from "@/views/organiser/Create.vue";
import Volunteer from "@/views/public/Volunteer.vue";
import Safeguarding from "@/views/public/Safeguarding.vue";
import Bluespace from "@/views/public/Bluespace.vue";
import Shop from "@/views/public/Shop.vue";
import Blogs from "@/views/public/Blogs.vue";
import Blog from "@/views/public/Blog.vue";
import NewIncidentReport from "@/views/organiser/incident-report/New.vue";
import EditIncidentReport from "@/views/organiser/incident-report/Edit.vue";
import AdminIncidentReports from "@/views/admin/incident-report/Index.vue";
import AdminIncidentReport from "@/views/admin/incident-report/Show.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import MeetTheTeam from "@/views/public/MeetTheTeam.vue";
import Supporters from "@/views/public/Supporters.vue";
import Sponsors from "@/views/public/Sponsors.vue";
import AdminLocations from "@/views/admin/locations/Index.vue";
import AdminLocationsNew from "@/views/admin/locations/New.vue";
import AdminLocationsEdit from "@/views/admin/locations/Edit.vue";
import AdminUsers from "@/views/admin/user/Index.vue";
import AdminUserEdit from "@/views/admin/user/Edit.vue";
import UserRaceResults from "@/views/user/UserRaceResults.vue";

Vue.use(VueRouter);

const requireVisitor = (to, from, next) => {
  var access = store.state.user == "";
  if (!access) {
    store.commit("flash", {
      type: "success",
      text: "You are already signed in",
    });
    next("/account");
  } else {
    next();
  }
};

const requireLogin = (to, from, next) => {
  var access = store.state.user;
  if (!access) {
    next("/sign-in");
  } else {
    next();
  }
};

const requireOrganiser = (to, from, next) => {
  var access = store.state.user.organiser;
  if (!access) {
    next("/");
  } else {
    next();
  }
};

const requireAdmin = (to, from, next) => {
  var access = store.state.user.admin;
  if (!access) {
    next("/");
  } else {
    next();
  }
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: Races,
    beforeEnter: requireLogin,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms,
  },
  {
    path: "/aquapaddle-event-terms-conditions",
    name: "EventTerms",
    component: EventTerms,
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: SignIn,
    beforeEnter: requireVisitor,
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp,
    beforeEnter: requireVisitor,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/reset-password/:id",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/account",
    name: "Account",
    component: Account,
    beforeEnter: requireLogin,
  },
  {
    path: "/profile/edit",
    name: "UserProfileEdit",
    component: UserProfileEdit,
    beforeEnter: requireLogin,
  },
  {
    path: "/profile/:tag",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/paddles",
    name: "Races",
    component: Races,
    beforeEnter: requireLogin,
  },
  {
    path: "/finished-paddles",
    name: "FinishedRaces",
    component: FinishedRaces,
    beforeEnter: requireLogin,
  },
  {
    path: "/paddles/:id",
    name: "Race",
    component: Race,
    beforeEnter: requireLogin,
  },
  {
    path: "/locations/:id",
    name: "Location",
    component: Location,
  },
  {
    path: "/user/event-registrations",
    name: "UserRaceRegistrations",
    component: UserRaceRegistrations,
    beforeEnter: requireLogin,
  },
  {
    path: "/user/event-results",
    name: "UserRaceResults",
    component: UserRaceResults,
    beforeEnter: requireLogin,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/paddles/:id/timer",
    name: "Timer",
    component: Timer,
    beforeEnter: requireOrganiser,
  },
  {
    path: "/organiser",
    name: "Organiser",
    component: OrganiserUpcoming,
    beforeEnter: requireOrganiser,
  },
  {
    path: "/organiser/finished",
    name: "OrganiserFinished",
    component: OrganiserFinished,
    beforeEnter: requireOrganiser,
  },
  {
    path: "/organiser/create",
    name: "OrganiserCreate",
    component: OrganiserCreate,
    beforeEnter: requireOrganiser,
  },
  {
    path: "/organiser/:id/edit",
    name: "OrganiserEdit",
    component: OrganiserEdit,
    beforeEnter: requireOrganiser,
  },
  {
    path: "/organiser/incident-reports/new/:id",
    name: "NewIncidentReport",
    component: NewIncidentReport,
    beforeEnter: requireOrganiser,
  },
  {
    path: "/organiser/incident-reports/:id/edit",
    name: "EditIncidentReport",
    component: EditIncidentReport,
    beforeEnter: requireOrganiser,
  },
  {
    path: "/volunteer",
    name: "Volunteer",
    component: Volunteer,
  },
  {
    path: "/volunteer/:slug",
    name: "VolunteerChildPage",
    component: Volunteer,
  },
  {
    path: "/safeguarding",
    name: "Safeguarding",
    component: Safeguarding,
  },
  {
    path: "/bluespace",
    name: "Bluespace",
    component: Bluespace,
  },
  {
    path: "/shop",
    name: "Shop",
    component: Shop,
  },
  {
    path: "/blog",
    name: "Blogs",
    component: Blogs,
  },
  {
    path: "/blog/:slug",
    name: "Blog",
    component: Blog,
  },
  {
    path: "/admin/incident-reports",
    name: "AdminIncidentReports",
    component: AdminIncidentReports,
    beforeEnter: requireAdmin,
  },
  {
    path: "/admin/incident-reports/:id",
    name: "AdminIncidentReport",
    component: AdminIncidentReport,
    beforeEnter: requireAdmin,
  },
  {
    path: "/admin/locations",
    name: "AdminLocations",
    component: AdminLocations,
    beforeEnter: requireAdmin,
  },
  {
    path: "/admin/locations/new",
    name: "AdminLocationsNew",
    component: AdminLocationsNew,
    beforeEnter: requireAdmin,
  },
  {
    path: "/admin/locations/:id/edit",
    name: "AdminLocationsEdit",
    component: AdminLocationsEdit,
    beforeEnter: requireAdmin,
  },
  {
    path: "/meet-the-team",
    name: "MeetTheTeam",
    component: MeetTheTeam,
  },
  {
    path: "/supporters",
    name: "Supporters",
    component: Supporters,
  },
  {
    path: "/sponsors",
    name: "Sponsors",
    component: Sponsors,
  },
  {
    path: "/admin/users",
    name: "AdminUsers",
    component: AdminUsers,
    beforeEnter: requireAdmin,
  },
  {
    path: "/admin/users/:id",
    name: "AdminUserEdit",
    component: AdminUserEdit,
    beforeEnter: requireAdmin,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + " | AquaPaddle";
  } else {
    document.title = "AquaPaddle";
  }
});

export default router;
