<template>
  <v-container>
    <page-header text="Results" />

    <v-simple-table v-if="results">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Date</th>
            <th class="text-left">Position</th>
            <th class="text-left">Paddle</th>
            <th class="text-left">Time</th>
            <th class="text-left">Watercraft</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in results" :key="item.id">
            <td>{{ prettyDate(item.race_date) }}</td>
            <td>{{ item.position }}</td>

            <td>
              <router-link :to="{ name: 'Race', params: { id: item.race_id } }">
                {{ item.race_name }}
              </router-link>
            </td>
            <td>{{ prettyTime(item.time) }}</td>
            <td><watercraft-icon :watercraft="item.watercraft" /></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import PageHeader from "@/components/public/PageHeader.vue";
import WatercraftIcon from "@/components/public/WatercraftIcon.vue";

export default {
  name: "user-race-results",

  components: {
    PageHeader,
    WatercraftIcon,
  },

  data: () => ({
    error: "",
    results: [],
  }),

  methods: {
    getResults() {
      fetch(`${process.env.VUE_APP_BACKEND_API_URL}/user/event-results`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        method: "GET",
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.results = data;
        }
      });
    },
  },

  mounted() {
    this.getResults();
  },
};
</script>
